import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Member from "../components/member";

const Team = ({ data }) => {
  const { description, banner, member } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo
        title={banner.title}
        description={description}
        imagePath={banner.bannerImage.publicURL}
      />
      <Hero
        fluid={banner.bannerImage.childImageSharp.fluid}
        title={banner.title}
      />
      <Member
        fluid={member.profileImage.childImageSharp.fluid}
        name={member.name}
        position={member.position}
        credentials={member.credentials}
        about={member.about}
      />
    </Layout>
  );
};

Team.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default Team;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "team" } }) {
      frontmatter {
        description
        banner {
          title
          bannerImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        member {
          profileImage {
            childImageSharp {
              fluid(maxWidth: 720) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          position
          credentials
          about
        }
      }
    }
  }
`;
