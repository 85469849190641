import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styles from "./member.module.scss";

const Member = props => {
  const { fluid, name, position, credentials, about } = props;
  return (
    <section className={styles.member}>
      <Img className={styles.profileImage} fluid={fluid} />
      <div className={styles.text}>
        <h5 className={styles.name}>{name}</h5>
        <p className={styles.position}>{position}</p>
        <em>
          <p className={styles.credentials}>{credentials}</p>
        </em>
        <p className={styles.about}>{about}</p>
      </div>
    </section>
  );
};

Member.propTypes = {
  fluid: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  credentials: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired
};

export default Member;
